import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'config/i18n';
// Material ui components
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
// Material ui icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// React components
import useHeaderMenuItems from 'data/useHeaderMenuItems';
import Link from 'components/shared/Link';
import Image from 'next/image';
import esLogo from 'public/imgs/eagleshare-logo.svg';
import CurrencySelector from '../TopBar/CurrencySelector';
import CountrySelector from '../TopBar/CountrySelector';
import MenuDialogs from './dialogs';

const drawerWidth = '20rem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: [theme.palette.secondary.main],
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: theme.spacing(0, 1.5),
    background: theme.palette.grey[800],
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    '& $homeLink': {
      top: theme.typography.pxToRem(5),
      position: 'relative',
    },
  },
  homeLink: {
    color: theme.palette.common.white,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  list: {
    padding: theme.spacing(0),
    "& [class*='MuiListItem-root']": {
      padding: [theme.spacing(0, 0.5), '!important'],
    },
  },
  listItemLink: {
    padding: theme.spacing(1.1, 1),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'relative',
    "& [class*='MuiSvgIcon-root']": {
      fontSize: '1rem',
      marginRight: theme.typography.pxToRem(4),
    },
  },
  // expandIcon: {
  //   fontSize: '1.2rem',
  //   margin: '0.5rem 0.7rem',
  //   color: theme.palette.text.secondary,
  // },
  // submenuItem: {
  //   "& [class*='MuiListItemText-root']": {
  //     marginTop: theme.typography.pxToRem(2),
  //     marginBottom: theme.typography.pxToRem(2),
  //   },
  //   '& $listItemLink': {
  //     fontSize: theme.typography.pxToRem(14),
  //     textTransform: 'capitalize',
  //   },
  //   '& $submenuIcon': {
  //     position: 'absolute',
  //     right: theme.typography.pxToRem(5),
  //   },
  // },
  // submenuIcon: {},
  drawerFooter: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '& $footerActions': {
      '& > *': {
        margin: theme.spacing(0, 1),
        width: '50%',
      },
    },
  },
  footerActions: {},
  rentBikeLink: {
    margin: theme.spacing(3, 2),
    borderColor: '#fff',
    color: theme.palette.common.white,
  },
}));

function MobileNavigationDrawer(props) {
  const {
    mobileOpen, handleDrawerClose, handleDrawerOpen, t,
  } = props;
  const classes = useStyles();

  const menuItems = useHeaderMenuItems(true);

  const [items, setItems] = React.useState(menuItems);

  // Check clicked menu items to open dialogs if necessarily
  const [clickedItem, setClickedItem] = React.useState(null);

  useEffect(() => {
    setItems(menuItems);
  }, [menuItems]);

  const handleItemClick = (item, index) => (event) => {
    if (item.submenu) {
      // expand or collapse submenu
      event.preventDefault();
      let oldItems = [...items];
      oldItems = oldItems.map((_item, i) => ({
        ..._item,
        isOpen: i === index ? !_item.isOpen : false, // Close all expanded items and toggle the clicked one
      }));
      setItems(oldItems);
      handleDrawerOpen(); // To keep side menu open in case of submenu
    // eslint-disable-next-line no-script-url
    } else if (item?.href && item.href.includes('javascript:')) {
      event.preventDefault();
      setClickedItem(item);
    }
  };

  const drawer = (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <IconButton edge="start" color="inherit" onClick={handleDrawerClose} aria-label="menu">
          <ArrowBackIcon />
        </IconButton>

        <Link href="/" className={classes.homeLink}>
          <Image
            width="130"
            height="20"
            priority
            unoptimized
            src={esLogo}
            alt="Eagleshare"
          />
        </Link>

      </div>
      <List className={classes.list}>
        {items.map((item, index) => (
          <React.Fragment key={item.text}>
            <ListItem
              onClickCapture={handleItemClick(item, index)} // Capture phase
              button
              selected={item.isOpen}
            >
              <ListItemText
                primary={(
                  <Link
                    className={classes.listItemLink}
                    onClick={handleDrawerClose || null}
                    href={item.href}
                    color="textSecondary"
                  >
                    {t(item.text)}
                  </Link>
                )}
              />
            </ListItem>
            {items.length !== index + 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <Divider />

      <Box>
        <Button
          variant="outlined"
          component={Link}
          href="/rent-my-motorcycle"
          className={classes.rentBikeLink}
          onClick={handleDrawerClose}
        >
          {t('fe_es_layout:navigation.rent_my_bike')}
        </Button>
      </Box>
      <div className={classes.drawerFooter}>
        <Box className={classes.footerActions} display="flex" justifyContent="space-around" m={2}>
          {mobileOpen && (
            <>
              <CurrencySelector />
              <CountrySelector />
            </>
          )}
        </Box>
      </div>
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {mobileOpen && drawer}
      </Drawer>
      {/* Custom trip && CheckInDialogs */}
      {clickedItem && <MenuDialogs item={clickedItem} onClose={() => setClickedItem(null)} />}
    </>
  );
}

export default withTranslation(['layout', 'fe_es_layout'])(MobileNavigationDrawer);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflowX: 'hidden', // for right/left slided popups
    },
    "[class*='MuiFormLabel-asterisk']": {
      color: '#e74d3d',
    },
    '& .MuiButton-containedPrimary, & .MuiButton-outlinedSecondary': {
      color: '#000 !important',
      fontWeight: 'bold !important',
    },
    '& .customLink': {
      color: '#000 !important',
      fontWeight: '600 !important',
      textDecoration: 'underline !important',
    },
    '.truncate': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.hidden-in-print': {
      '@media print': {
        display: 'none',
      },
    },
    'button,a': {
      '@media print': {
        display: 'none !important',
      },
    },
    // Table styles
    "[class*='MuiTableContainer-root']": {
      boxShadow:
        '0px 1px 0px -1px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.1)',
      borderRadius: 0,
    },
    table: {
      "& tbody [class*='MuiTableRow-root']": {
        '&:nth-of-type(odd):not(.tfoot)': {
          backgroundColor: '#f9f9f9',
        },
      },
      "& [class*='MuiTableCell-root']": {
        padding: '0.5rem 0.625rem 0.625rem !important',
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.pxToRem(15),
        },
        '&.table-actions': {
          '@media print': {
            display: 'none',
          },
          "&[class*='MuiTableCell-body']": {
            fontWeight: 500,
          },
        },
      },
      "& thead [class*='MuiTableCell-root']": {
        textAlign: 'left',
        textTransform: 'capitalize',
        fontWeight: 'bold',
      },
      '& .tfoot': {
        '& > *': {
          fontWeight: 900,
        },
      },
    },
    '.tableRowCard': {
      // Table in mobile
      marginBottom: theme.typography.pxToRem(1),
      fontSize: theme.typography.pxToRem(16),
      width: '100%',
      '&:nth-of-type(even)': {
        backgroundColor: '#f9f9f9',
      },
    },
    '.tableRowCard__column': {
      '&.table-actions, & .table-actions ': {
        fontWeight: 500,
        '@media print': {
          display: 'none',
        },
      },
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      '&.total': {
        justifyContent: 'center',
        '& > *': {
          fontWeight: 900,
        },
      },
      '& .tableRowCard__column-name': {
        fontWeight: 600,
      },
    },
    '.iti-mobile .intl-tel-input.iti-container': {
      zIndex: 99999,
    },
  },
}));

const GlobalStyles = () => {
  useStyles();
  return (
    <>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
    </>
  );
};

export default GlobalStyles;
